import { graphql, useStaticQuery } from "gatsby"
import { pageFinder } from "../helpers/pageFinder"

export const useItems = itemName => {
  const items = useStaticQuery(
    graphql`
      query {
        allStrapiItems {
          edges {
            node {
              Name
              content
            }
          }
        }
      }
    `
  )
  const item = pageFinder(items, itemName, "allStrapiItems")

  return item
}
